import { IconDefinition, faBoltLightning, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Selector } from "raci-react-library";

type Perk = { message: string; icon: IconDefinition };

const lifeTimeRepairGuaranteePerk = { message: "Lifetime guarantee on repairs", icon: faCheck } satisfies Perk;
const qualityRepairFacilityPerk = { message: "Quality repair facility", icon: faCheck } satisfies Perk;
const electricVehicleRepairerPerk = { message: "Electric vehicle repairer", icon: faBoltLightning } satisfies Perk;
const fastTrackedAssessmentPerk = { message: "Fast-tracked assessments", icon: faCheck } satisfies Perk;
const complimentaryCarHirePerk = {
  message: "Complimentary compact hire car (if available)",
  icon: faCheck,
} satisfies Perk;

export type RepairerPerksProps = {
  electricVehicleRepairer?: boolean;
  fastTrackedAssessment?: boolean;
  complimentaryCarHire?: boolean;
};

export const RepairerPerks = ({
  electricVehicleRepairer,
  fastTrackedAssessment,
  complimentaryCarHire,
}: RepairerPerksProps) => {
  const isMobileCardVariant = useMediaQuery(`(max-width:${Selector.SelectionCard.Breakpoint}px)`);

  const perks = [lifeTimeRepairGuaranteePerk, qualityRepairFacilityPerk];
  electricVehicleRepairer && perks.unshift(electricVehicleRepairerPerk);
  fastTrackedAssessment && perks.push(fastTrackedAssessmentPerk);
  complimentaryCarHire && perks.push(complimentaryCarHirePerk);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${isMobileCardVariant ? "1" : "2"}, 1fr)`,
        columnGap: "16px",
        rowGap: "8px",
      }}
    >
      {perks.map((perk) => (
        <Box key={perk.message} sx={{ display: "flex", alignItems: "flex-start", gap: "6px" }}>
          <FontAwesomeIcon icon={perk.icon} style={{ height: "18px", marginTop: "4px" }} />
          <Typography variant="body1">{perk.message}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default RepairerPerks;
