import { useMsal } from "@azure/msal-react";
import { gtm, virtualPageView } from "@racwa/analytics";
import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import {
  EMPTY_URL,
  HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE,
  HTTP_STATUS_CODE_NOT_FOUND,
  useHasSession,
  useIsLoggedInToMyRac,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW,
  STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED,
  STORAGE_KEY_POLICY_NUMBER,
} from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { useReferenceData } from "../../../../shared/hooks/useReferenceData";
import { ErrorRoute, FormRoute, allRoutes } from "../../../../shared/routing/routes.config";
import useDocumentTitle from "../../../../shared/routing/useDocumentTitle";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { YourPolicyState } from "../../../YourPolicy/types";
import { BeforeYouStartFormProps, BeforeYouStartState, RedirectType } from "../../types";

export const useBeforeYouStart = (): BeforeYouStartFormProps => {
  useReferenceData();
  const navigate = useNavigateToRoute();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const { instance: msalInstance } = useMsal();
  const isLoggedIn = useIsLoggedInToMyRac();
  const hasSession = useHasSession();
  const documentTitle = useDocumentTitle(allRoutes);
  const [beforeYouStartState, setBeforeYouStartState] = useSessionState<BeforeYouStartState>({
    skipPageTrackingRecalculation: true,
  });
  const [yourPolicyState, setYourPolicyState] = useSessionState<YourPolicyState>({
    specificKey: FormRoute.YourPolicy,
    skipPageTrackingRecalculation: true,
  });
  const [startYourClaimState, setStartYourClaimState] = useSessionState<StartYourClaimState>({
    specificKey: FormRoute.StartYourClaim,
    skipPageTrackingRecalculation: true,
  });
  const [disabled, setDisabled] = useState(false);

  const policyNumber = sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);
  const loginRegisterRedirectTriggered = !!sessionStorage.getItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED);
  const hasAttemptedToEnterFlow = !!sessionStorage.getItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW);

  const getPolicy = async (policyNumber: string) => {
    try {
      const policyDetails = (await apiClient.getPolicy(policyNumber)).result;

      sessionStorage.setItem(STORAGE_KEY_POLICY_NUMBER, policyNumber);
      setYourPolicyState({
        ...yourPolicyState,
        policyNumber,
        isCompleted: true,
      });

      setStartYourClaimState({
        ...startYourClaimState,
        policyDetails,
      });

      setBeforeYouStartState({
        ...beforeYouStartState,
        isCompleted: true,
      });

      navigate(FormRoute.ContactDetails);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      sessionStorage.removeItem(STORAGE_KEY_POLICY_NUMBER);

      const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";
      const isMemberContactError = enableChangeMyDetails && error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;

      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `GET /policy`, status: error.status },
          isMemberContactError,
        },
      });
    }
  };

  const redirectToNextPage = async () => {
    sessionStorage.setItem(STORAGE_KEY_HAS_ATTEMPTED_TO_ENTER_FLOW, true.toString());

    if (policyNumber) {
      return await getPolicy(policyNumber);
    }

    try {
      const response = await apiClient.getPolicies();
      const policies = response.result.policies;

      if (policies?.length === 1) {
        return await getPolicy(policies[0]?.policyNumber ?? "");
      }

      setBeforeYouStartState({
        ...beforeYouStartState,
        policies,
        isCompleted: true,
      });

      navigate(FormRoute.ContactDetails);
    } catch (ex) {
      const error = ex as ClaimsMotorCollisionApiException;
      const errorRoute =
        error?.status === HTTP_STATUS_CODE_NOT_FOUND ? ErrorRoute.YouCantMakeAClaim : ErrorRoute.SystemUnavailable;

      const enableChangeMyDetails = process.env.REACT_APP_FEATURE_CHANGE_MY_DETAILS === "true";
      const isMemberContactError = enableChangeMyDetails && error.status === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE;
      navigate(errorRoute, {
        state: {
          referrer: location.pathname,
          exception: { request: "GET /policies", status: error.status },
          isMemberContactError,
        },
      });
    }
  };

  const handleRedirect = async (redirectType: RedirectType) => {
    setBackdrop(true);
    setDisabled(true);

    if (redirectType === RedirectType.Next) {
      await redirectToNextPage();
    } else {
      sessionStorage.setItem(STORAGE_KEY_LOGIN_REGISTER_REDIRECT_TRIGGERED, true.toString());
      await msalInstance.loginRedirect({ redirectUri: process.env.REACT_APP_ADB2C_RETURN_URL, scopes: [] });
    }

    setBackdrop(false);
    setDisabled(false);
  };

  // We do not want to record page view when it is redirected
  useEffect(() => {
    if (!loginRegisterRedirectTriggered || hasAttemptedToEnterFlow) {
      gtm(virtualPageView({ url: location.pathname.toLocaleLowerCase(), title: documentTitle }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to next page automatically if returning from login / linking
  useEffect(() => {
    const isReturningFromLoginOrLinking = loginRegisterRedirectTriggered && !hasAttemptedToEnterFlow;

    if (isLoggedIn && hasSession && isReturningFromLoginOrLinking) {
      handleRedirect(RedirectType.Next);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAttemptedToEnterFlow, isLoggedIn, loginRegisterRedirectTriggered, hasSession]);

  return {
    isLoggedIn,
    disabled,
    privacyUrl: process.env.REACT_APP_RAC_ABOUT_PRIVACY_PAGE ?? EMPTY_URL,
    handleRedirect,
  };
};

export default useBeforeYouStart;
