import { DriverHistoryQuestions } from "../../../../../DriverHistory/constants";
import { DriverHistoryFormValues, DriverHistoryState } from "../../../../../DriverHistory/types";
import { DriverOfYourCarState } from "../../../../../DriverOfYourCar/types";
import { FormSummary } from "../../../../types";

type Args = Pick<DriverOfYourCarState, "driverName"> & Pick<DriverHistoryState, keyof DriverHistoryFormValues>;

export const summariseDriverHistory = ({
  driverName,
  driverWasUnderTheInfluence,
  driverHasValidLicence,
  driverHasLicenceSuspensionOrCancellation,
  suspensionOrCancellationDetails,
}: Args) =>
  ({
    driverWasUnderTheInfluence: {
      question: DriverHistoryQuestions.driverWasUnderTheInfluence.label({ driverName }),
      answer: driverWasUnderTheInfluence,
      customId: DriverHistoryQuestions.driverWasUnderTheInfluence.name,
    },
    driverHasValidLicence: driverHasValidLicence
      ? {
          question: DriverHistoryQuestions.driverHasValidLicence.label({ driverName }),
          answer: driverHasValidLicence,
          customId: DriverHistoryQuestions.driverHasValidLicence.name,
        }
      : undefined,
    driverHasLicenceSuspensionOrCancellation: {
      question: DriverHistoryQuestions.driverHasLicenceSuspensionOrCancellation.label({ driverName }),
      answer: driverHasLicenceSuspensionOrCancellation,
      customId: DriverHistoryQuestions.driverHasLicenceSuspensionOrCancellation.name,
    },
    suspensionOrCancellationDetails: suspensionOrCancellationDetails
      ? {
          question: DriverHistoryQuestions.suspensionOrCancellationDetails.label,
          answer: suspensionOrCancellationDetails,
        }
      : undefined,
  }) as const satisfies FormSummary<DriverHistoryFormValues>;

export default summariseDriverHistory;
