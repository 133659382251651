import {
  ClaimsMotorCollisionApiException,
  DriverDetails,
  DriverOfYourCarRequest,
} from "raci-claims-motor-collision-clientproxy";
import {
  RadioButtonGroupItem,
  toLocalDateOnlyISOString,
  useGetSessionState,
  useSessionState,
  useSetBackdrop,
} from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useSearchAddress from "../../../../shared/hooks/useSearchAddress";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { DriverHistoryState } from "../../../DriverHistory/types";
import { MoreAboutTheAccidentState } from "../../../MoreAboutTheAccident/types";
import { OtherDriverItem } from "../../constants";
import { DriverOfYourCarFormProps, DriverOfYourCarFormValues, DriverOfYourCarState } from "../../types";

export const useDriverOfYourCar = (): DriverOfYourCarFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const addressInputProps = useSearchAddress();
  const { additionalDrivers } = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const [driverOfYourCarState, setDriverOfYourCarState] = useSessionState<DriverOfYourCarState>();
  const [, setDriverHistoryState] = useSessionState<DriverHistoryState>({ specificKey: FormRoute.DriverHistory });

  const driverOptions: RadioButtonGroupItem[] =
    additionalDrivers?.map((d: DriverDetails) => ({
      id: d.id!,
      value: d.id!,
      label: d.firstName! + (d.middleName ? " " + d.middleName : "") + (d.lastName ? " " + d.lastName : ""),
    })) ?? [];

  if (driverOptions.length > 0) {
    driverOptions.push(OtherDriverItem);
  }

  const form = useForm<DriverOfYourCarFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      ...driverOfYourCarState,
      otherDriverDateOfBirth: driverOfYourCarState.otherDriverDateOfBirth
        ? new Date(driverOfYourCarState.otherDriverDateOfBirth)
        : undefined,
    },
  });

  const onSubmit: DriverOfYourCarFormProps["onSubmit"] = async (newValues) => {
    const driverName = newValues.isDriver
      ? undefined
      : newValues.policyDriver && newValues.policyDriver !== OtherDriverItem.value
        ? additionalDrivers?.find((d) => d.id === newValues.policyDriver)?.firstName
        : newValues.otherDriverFirstName;

    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: driverOfYourCarState })) {
        await apiClient.driverOfYourCar(createRequest(newValues));
        setDriverHistoryState({ isCompleted: false });
      }

      setDriverOfYourCarState({
        ...newValues,
        driverName,
        isCompleted: true,
      });

      navigate(FormRoute.DriverHistory);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/driver-of-your-car`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    form,
    driverOptions,
    addressInputProps,
    onSubmit,
  };
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: DriverOfYourCarFormValues;
  oldAnswers: DriverOfYourCarState;
}) =>
  newAnswers.isDriver !== oldAnswers.isDriver ||
  newAnswers.policyDriver !== oldAnswers.policyDriver ||
  newAnswers.otherDriverFirstName !== oldAnswers.otherDriverFirstName ||
  newAnswers.otherDriverMiddleName !== oldAnswers.otherDriverMiddleName ||
  newAnswers.otherDriverLastName !== oldAnswers.otherDriverLastName ||
  JSON.stringify(newAnswers.otherDriverDateOfBirth) !== JSON.stringify(oldAnswers.otherDriverDateOfBirth) ||
  newAnswers.otherDriverContactNumber !== oldAnswers.otherDriverContactNumber ||
  newAnswers.otherDriverAddress?.moniker !== oldAnswers.otherDriverAddress?.moniker;

const createRequest = (formValues: DriverOfYourCarFormValues) =>
  ({
    isDriver: formValues.isDriver,
    policyDriverId: formValues.policyDriver !== OtherDriverItem.value ? formValues.policyDriver : undefined,
    otherDriver:
      formValues.otherDriverFirstName && formValues.otherDriverLastName && formValues.otherDriverDateOfBirth
        ? {
            firstName: formValues.otherDriverFirstName,
            middleName: formValues.otherDriverMiddleName,
            lastName: formValues.otherDriverLastName,
            dateOfBirth: toLocalDateOnlyISOString(formValues.otherDriverDateOfBirth),
            contactNumber: formValues.otherDriverContactNumber,
            addressMoniker: formValues.otherDriverAddress?.moniker,
          }
        : undefined,
  }) satisfies DriverOfYourCarRequest;

export default useDriverOfYourCar;
