import * as htmlToImage from "html-to-image";
import { ClaimsMotorCollisionApiException, WhereAndHowRequest } from "raci-claims-motor-collision-clientproxy";
import { YesNoImNotSure, useSessionState, useSetBackdrop } from "raci-react-library";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { GOOGLE_MAPS_API_KEY } from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import { ErrorRoute, FormRoute } from "../../../../shared/routing/routes.config";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { WerePoliceInvolvedYesNoImNotSure, WhereAndHowQuestions } from "../../constants";
import { ProvideTheLocation, WhereAndHowFormProps, WhereAndHowFormValues, WhereAndHowState } from "../../types";

export const useWhereAndHow = (): WhereAndHowFormProps => {
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const location = useLocation();
  const [whereAndHowState, setWhereAndHowState] = useSessionState<WhereAndHowState>();

  const form = useForm<WhereAndHowFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: whereAndHowState,
  });

  const onSubmit: WhereAndHowFormProps["onSubmit"] = async (newValues) => {
    try {
      setBackdrop(true);

      if (answersHaveChanged({ newAnswers: newValues, oldAnswers: whereAndHowState })) {
        await apiClient.whereAndHow(createRequest(newValues));
      }

      let mapDataImage = undefined;
      if (newValues.whereTheIncidentHappenedMap) {
        const el = document.getElementById(`${WhereAndHowQuestions.whereTheIncidentHappenedMap.id}`);
        if (el !== null) {
          mapDataImage = await htmlToImage.toPng(el, { includeQueryParams: true, cacheBust: true });
        }
      }

      setWhereAndHowState({
        ...newValues,
        mapDataImage,
        isCompleted: true,
      });
      navigate(FormRoute.DriverOfYourCar);
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /step/where-and-how`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false);
    }
  };

  return {
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    form,
    onSubmit,
  };
};

const answersHaveChanged = ({
  newAnswers,
  oldAnswers,
}: {
  newAnswers: WhereAndHowFormValues;
  oldAnswers: WhereAndHowState;
}) => JSON.stringify(oldAnswers) !== JSON.stringify(newAnswers);

const createRequest = (formValues: WhereAndHowFormValues) =>
  ({
    howTheIncidentHappened: formValues.howTheIncidentHappened,
    whereTheIncidentHappenedDescription:
      formValues.provideTheLocation === ProvideTheLocation.UseAMap
        ? undefined
        : formValues.whereTheIncidentHappenedDescription,
    whereTheIncidentHappenedMap:
      formValues.provideTheLocation === ProvideTheLocation.UseAMap
        ? {
            latitude: formValues.whereTheIncidentHappenedMap?.latitude,
            longitude: formValues.whereTheIncidentHappenedMap?.longitude,
          }
        : undefined,
    werePoliceInvolved:
      formValues.werePoliceInvolved && WerePoliceInvolvedYesNoImNotSure[formValues.werePoliceInvolved],
    policeReportNumber:
      formValues.werePoliceInvolved === YesNoImNotSure.Yes ? formValues.policeReportNumber : undefined,
  }) satisfies WhereAndHowRequest;

export default useWhereAndHow;
