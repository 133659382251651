import { LoaderOptions } from "@googlemaps/js-api-loader";
import { Grid } from "@mui/material";
import { NotificationCard } from "raci-react-library";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import GoogleMarker from "../GoogleMarker";
import useRacwaMaps from "./useRacwaMaps";

export interface Marker {
  address: string;
  latitude: number;
  longitude: number;
  zoom?: number;
}

type GoogleMap = google.maps.Map;

export interface RacwaMapsProps extends LoaderOptions {
  /**
   * The initial Map mapTypeId. Defaults to <code>hybrid</code>.
   */
  mapType?: "roadmap" | "satellite" | "hybrid" | "terrain";

  searchControlId?: string;

  disabled?: boolean;

  onLocationSelected?: (location: Marker) => void;

  value?: Marker;

  id: string;

  apiKey: string;

  placeId?: string;

  height?: string;
}

export const defaultWACoordinates: google.maps.LatLngLiteral = {
  lat: -32.0006770002319,
  lng: 115.82503970406208,
};

export interface RacwaMapsMethods {
  goToPlaceId: (placeId: string) => void;
}

export type RefAddress = google.maps.LatLngLiteral;

export const RacwaMaps = forwardRef<RacwaMapsMethods, RacwaMapsProps>(
  ({ apiKey, height, id, value, onLocationSelected, mapType }, RacwaMapsMethods) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMap>();
    const [googleMapsAvailable, setGoogleMapsAvailable] = useState<boolean>(true);
    const [location, setLocation] = useState<google.maps.LatLngLiteral>();
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
    const { addMapEventListener, removeMapEventListener, panToPlace, membersLocation } = useRacwaMaps(
      setLocation,
      map,
      onLocationSelected,
    );

    useImperativeHandle(RacwaMapsMethods, () => ({
      goToPlaceId: (placeId) => {
        placesService?.getDetails({ placeId: placeId }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            panToPlace(place);
          } else {
            setGoogleMapsAvailable(false);
          }
        });
      },
    }));

    useEffect(() => {
      let isMounted = true;
      let zoomChangedlistener: google.maps.MapsEventListener | undefined;
      let dragendlistener: google.maps.MapsEventListener | undefined;

      if (isMounted) {
        zoomChangedlistener = addMapEventListener("zoom_changed");
        dragendlistener = addMapEventListener("dragend");
      }

      return () => {
        isMounted = false;

        removeMapEventListener(zoomChangedlistener);
        removeMapEventListener(dragendlistener);
      };
    }, [addMapEventListener, removeMapEventListener]);

    useEffect(() => {
      let isMounted = true;

      if (isMounted && ref.current && !map && apiKey !== "") {
        if (location === undefined) {
          setLocation(defaultWACoordinates);
        }

        const mapResult = new google.maps.Map(ref.current as HTMLElement, {
          center: {
            lat: value?.latitude ?? defaultWACoordinates.lat,
            lng: value?.longitude ?? defaultWACoordinates.lng,
          },
          zoom: value?.zoom,
          mapTypeId: mapType,
          tilt: 0,
          streetViewControl: false,
          fullscreenControl: false,
        });
        setMap(mapResult);
        setPlacesService(new google.maps.places.PlacesService(mapResult));
      }

      return () => {
        isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, location]);

    useEffect(() => {
      let isMounted = true;

      if (!value) {
        membersLocation(isMounted);
      }
      return () => {
        isMounted = false;
      };
    }, [membersLocation, value]);

    return (
      <>
        {!googleMapsAvailable && (
          <NotificationCard
            severity="error"
            message={{
              title: "Maps is unavailable. Please select 'Describe location' instead.",
            }}
          />
        )}
        {googleMapsAvailable && (
          <Grid container id={`${id}-container`} item xs={12} sx={{ display: "grid", alignItems: "center" }}>
            <GoogleMarker
              sx={{
                gridRow: "1",
                gridColumn: "1",
                zIndex: "1",
                justifySelf: "center",
                justifyContent: "center",
                transform: "scale(3.4) translate(0,-50%)",
              }}
            />
            <div
              ref={ref}
              id={`${id}-map`}
              style={{ width: "100%", height: height ?? "360px", gridRow: "1", gridColumn: "1" }}
            />
          </Grid>
        )}
      </>
    );
  },
);

export default RacwaMaps;
