import { Box, Button, Grid } from "@mui/material";
import { event, gtm } from "@racwa/analytics";
import { CarGRFX001NoPadding, PolicyDetailsCard } from "@racwa/react-components";
import { InformationSummary, InformationSummarySection } from "raci-react-library";
import { FormProvider } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { FormRoute } from "../../shared/routing/routes.config";
import { aboutTheAccidentPageTitle } from "../AboutTheAccident/constants";
import { aboutYourCarPageTitle } from "../AboutYourCar/constants";
import { contactDetailsPageTitle } from "../ContactDetails/constants";
import { driverHistoryPageTitle } from "../DriverHistory/constants";
import { driverOfYourCarPageTitle } from "../DriverOfYourCar/constants";
import { moreAboutTheAccidentPageTitle } from "../MoreAboutTheAccident/constants";
import { moreThirdPartyDetailsPageTitle } from "../MoreThirdPartyDetails/constants";
import { thirdPartyDetailsPageTitle } from "../ThirdPartyDetails/constants";
import { whereAndHowItHappenedPageTitle } from "../WhereAndHow/constants";
import { wheresYourCarPageTitle } from "../WheresYourCar/constants";
import { witnessDetailsPageTitle } from "../WitnessDetails/constants";
import SummarySection from "./components/SummarySection";
import WhereAndHowSummarySection from "./components/WhereAndHowSummarySection";
import { StyledNotificationCard, StyledSummaryContainer } from "./styled";
import { ReviewYourClaimFormProps } from "./types";

const pdsText = "Product Disclosure Statement";

export const ReviewYourClaimForm = ({
  form,
  policyDetails,
  isSingleVehicleCollisionClaim,
  driverName,
  formSummaries,
  onSubmit,
  onEditSection,
}: ReviewYourClaimFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const {
    contactDetails,
    startYourClaim,
    aboutTheAccident,
    moreAboutTheAccident,
    whereAndHow,
    driverOfYourCar,
    driverHistory,
    thirdPartyDetails,
    moreThirdPartyDetails,
    witnessDetails,
    aboutYourCar,
    wheresYourCar,
  } = formSummaries;
  const driverHistoryTitle = driverHistoryPageTitle({ driverName });
  const thirdPartyDetailsTitle = thirdPartyDetailsPageTitle({ isSingleVehicleCollisionClaim });
  const moreThirdPartyDetailsTitle = moreThirdPartyDetailsPageTitle({ isSingleVehicleCollisionClaim });

  const policyDetailsCard = (
    <PolicyDetailsCard
      id="summary"
      policyTitle={`${policyDetails?.year} ${policyDetails?.make}`}
      policySubtitle={policyDetails?.registrationNumber}
      minWidth="50"
      maxWidth="200"
      cardActionTitle={pdsText}
      cardActionLink={process.env.REACT_APP_RAC_PDS_PED_URL}
      cardActionOnClick={() => {
        gtm(event(pdsText));
      }}
      showCancelledRibbon={false}
      properties={[{ key: "Policy number", value: policyDetails?.policyNumber ?? "" }]}
    >
      <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
        <CarGRFX001NoPadding />
      </Box>
    </PolicyDetailsCard>
  );

  const visibleSections: InformationSummarySection[] = [
    {
      id: "claim-type",
      title: "Claim type",
      subsections: [
        {
          id: "car-accident",
          content: [<SummarySection formSummary={{ sectionContent: { question: "", answer: "Car accident" } }} />],
        },
      ],
    },
    {
      id: "contact-details-section",
      title: contactDetailsPageTitle,
      subsections: [
        {
          id: "contact-details-subsection",
          content: [<SummarySection formSummary={contactDetails} />],
          onEditClicked: () => onEditSection(contactDetailsPageTitle, FormRoute.ContactDetails),
        },
      ],
    },
    {
      id: "when-it-happened-section",
      title: "When it happened",
      subsections: [
        {
          id: "when-it-happened-subsection",
          content: [<SummarySection formSummary={startYourClaim} />],
        },
      ],
    },
  ];

  const hiddenSections: InformationSummarySection[] = [
    {
      id: "about-the-accident",
      title: aboutTheAccidentPageTitle,
      subsections: [
        {
          id: "about-the-accident-subsection",
          content: [<SummarySection formSummary={aboutTheAccident} />],
        },
      ],
    },
    {
      id: "more-about-the-accident",
      title: moreAboutTheAccidentPageTitle,
      subsections: [
        {
          id: "more-about-the-accident-subsection",
          content: [<SummarySection formSummary={moreAboutTheAccident} />],
        },
      ],
    },
    {
      id: "where-and-how",
      title: whereAndHowItHappenedPageTitle,
      subsections: [
        {
          id: "where-and-how-subsection",
          content: [<WhereAndHowSummarySection {...whereAndHow} />],
          onEditClicked: () => onEditSection(whereAndHowItHappenedPageTitle, FormRoute.WhereAndHow),
        },
      ],
    },
    {
      id: "driver-of-your-car",
      title: driverOfYourCarPageTitle,
      subsections: [
        {
          id: "driver-of-your-car-subsection",
          content: [<SummarySection formSummary={driverOfYourCar} />],
          onEditClicked: () => onEditSection(driverOfYourCarPageTitle, FormRoute.DriverOfYourCar),
        },
      ],
    },
    {
      id: "driver-history",
      title: driverHistoryTitle,
      subsections: [
        {
          id: "driver-history-subsection",
          content: [<SummarySection formSummary={driverHistory} />],
          onEditClicked: () => onEditSection("Driver history", FormRoute.DriverHistory),
        },
      ],
    },
    ...(thirdPartyDetails
      ? [
          {
            id: "third-party-details",
            title: thirdPartyDetailsTitle,
            subsections: [
              {
                id: "third-party-details-subsection",
                content: [<SummarySection formSummary={thirdPartyDetails} />],
                onEditClicked: () => onEditSection(thirdPartyDetailsTitle, FormRoute.ThirdPartyDetails),
              },
            ],
          },
        ]
      : []),
    ...(moreThirdPartyDetails
      ? [
          {
            id: "more-third-party-details",
            title: moreThirdPartyDetailsTitle,
            subsections: [
              {
                id: "more-third-party-details-subsection",
                content: [<SummarySection formSummary={moreThirdPartyDetails} />],
                onEditClicked: () => onEditSection(moreThirdPartyDetailsTitle, FormRoute.MoreThirdPartyDetails),
              },
            ],
          },
        ]
      : []),
    {
      id: "witness-details",
      title: witnessDetailsPageTitle,
      subsections: [
        {
          id: "witness-details-subsection",
          content: [witnessDetails.witnesses.length > 0 ? <SummarySection formSummary={witnessDetails} /> : <p>-</p>],
          onEditClicked: () => onEditSection(witnessDetailsPageTitle, FormRoute.WitnessDetails),
        },
      ],
    },
    ...(aboutYourCar
      ? [
          {
            id: "about-your-car",
            title: aboutYourCarPageTitle,
            subsections: [
              {
                id: "about-your-car-subsection",
                content: [<SummarySection formSummary={aboutYourCar} />],
                onEditClicked: () => onEditSection(aboutYourCarPageTitle, FormRoute.AboutYourCar),
              },
            ],
          },
        ]
      : []),
    ...(wheresYourCar
      ? [
          {
            id: "where-is-your-car",
            title: wheresYourCarPageTitle,
            subsections: [
              {
                id: "where-is-your-car-subsection",
                content: [<SummarySection formSummary={wheresYourCar} />],
                onEditClicked: () => onEditSection(wheresYourCarPageTitle, FormRoute.WheresYourCar),
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id="review-your-claim-header" title="Review and confirm your claim" showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <StyledSummaryContainer>
              <InformationSummary
                id="review-your-claim"
                title="Your claim summary"
                subtitle={policyDetailsCard}
                visibleSections={visibleSections}
                hiddenSections={hiddenSections}
                expandText="More claim details"
                collapseText="Hide claim details"
              />
            </StyledSummaryContainer>
            <Grid item xs={12}>
              <StyledNotificationCard
                severity="info"
                message={{
                  title: "After this, you won't be able to make any changes online",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                id="submit-button"
                color="primary"
                variant="contained"
                fullWidth
                sx={{ margin: "24px 0 32px 0" }}
                disabled={isSubmitting}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ReviewYourClaimForm;
