import { event, gtm, virtualPageView } from "@racwa/analytics";
import { ClaimsMotorCollisionApiException } from "raci-claims-motor-collision-clientproxy";
import { useGetSessionState, useSessionState, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { TrackPageStatus } from "../../../../shared/components/TrackPageChanges/types";
import { BackdropMessage, STORAGE_KEY_TRACK_PAGE_VIEWS } from "../../../../shared/constants";
import { useBffApiClient } from "../../../../shared/hooks/useApiClient";
import useClaimState from "../../../../shared/hooks/useClaimState";
import { ErrorRoute, FormRoute, allRoutes } from "../../../../shared/routing/routes.config";
import useDocumentTitle from "../../../../shared/routing/useDocumentTitle";
import useNavigateToRoute from "../../../../shared/routing/useNavigateToRoute";
import { AboutTheAccidentState } from "../../../AboutTheAccident/types";
import { AboutYourCarState } from "../../../AboutYourCar/types";
import { ConfirmationState } from "../../../Confirmation/types";
import { ContactDetailsState } from "../../../ContactDetails/types";
import { DriverHistoryState } from "../../../DriverHistory/types";
import { DriverOfYourCarState } from "../../../DriverOfYourCar/types";
import { MoreAboutTheAccidentState } from "../../../MoreAboutTheAccident/types";
import { MoreThirdPartyDetailsState } from "../../../MoreThirdPartyDetails/types";
import { RepairerOptionsState } from "../../../RepairerOptions/types";
import { StartYourClaimState } from "../../../StartYourClaim/types";
import { ThirdPartyDetailsState } from "../../../ThirdPartyDetails/types";
import { WhereAndHowState } from "../../../WhereAndHow/types";
import { WheresYourCarState } from "../../../WheresYourCar/types";
import { WitnessDetailsState } from "../../../WitnessDetails/types";
import { ReviewYourClaimFormProps, ReviewYourClaimFormValues, ReviewYourClaimState } from "../../types";
import summariseAboutTheAccident from "./summarisers/summariseAboutTheAccident";
import summariseAboutYourCar from "./summarisers/summariseAboutYourCar";
import summariseContactDetails from "./summarisers/summariseContactDetails";
import summariseDriverHistory from "./summarisers/summariseDriverHistory";
import summariseDriverOfYourCar from "./summarisers/summariseDriverOfYourCar";
import summariseMoreAboutTheAccident from "./summarisers/summariseMoreAboutTheAccident";
import summariseMoreThirdPartyDetails from "./summarisers/summariseMoreThirdPartyDetails";
import summariseStartYourClaim from "./summarisers/summariseStartYourClaim";
import summariseThirdPartyDetails from "./summarisers/summariseThirdPartyDetails";
import summariseWhereAndHow from "./summarisers/summariseWhereAndHow";
import summariseWheresYourCar from "./summarisers/summariseWheresYourCar";
import summariseWitnessDetails from "./summarisers/summariseWitnessDetails";

export const useReviewYourClaim = (): ReviewYourClaimFormProps => {
  const location = useLocation();
  const navigate = useNavigateToRoute();
  const setBackdrop = useSetBackdrop();
  const apiClient = useBffApiClient();
  const documentTitle = useDocumentTitle(allRoutes);
  const { isLiabilityOnlyClaim, isSingleVehicleCollisionClaim } = useClaimState();
  const contactDetailsState = useGetSessionState<ContactDetailsState>(FormRoute.ContactDetails);
  const startYourClaimState = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const aboutTheAccidentState = useGetSessionState<AboutTheAccidentState>(FormRoute.AboutTheAccident);
  const moreAboutTheAccidentState = useGetSessionState<MoreAboutTheAccidentState>(FormRoute.MoreAboutTheAccident);
  const whereAndHowState = useGetSessionState<WhereAndHowState>(FormRoute.WhereAndHow);
  const driverOfYourCarState = useGetSessionState<DriverOfYourCarState>(FormRoute.DriverOfYourCar);
  const driverHistoryState = useGetSessionState<DriverHistoryState>(FormRoute.DriverHistory);
  const thirdPartyDetailsState = useGetSessionState<ThirdPartyDetailsState>(FormRoute.ThirdPartyDetails);
  const moreThirdPartyDetailsState = useGetSessionState<MoreThirdPartyDetailsState>(FormRoute.MoreThirdPartyDetails);
  const witnessDetailsState = useGetSessionState<WitnessDetailsState>(FormRoute.WitnessDetails);
  const aboutYourCarState = useGetSessionState<AboutYourCarState>(FormRoute.AboutYourCar);
  const wheresYourCarState = useGetSessionState<WheresYourCarState>(FormRoute.WheresYourCar);
  const [reviewYourClaimState, setReviewYourClaimState] = useSessionState<ReviewYourClaimState>();
  const [, setRepairerOptionsState] = useSessionState<RepairerOptionsState>({ specificKey: FormRoute.RepairerOptions });
  const [, setConfirmationState] = useSessionState<ConfirmationState>({ specificKey: FormRoute.Confirmation });
  const [trackPageViewState] = useState<TrackPageStatus>(() => {
    const trackPageState = sessionStorage.getItem(STORAGE_KEY_TRACK_PAGE_VIEWS);
    return trackPageState
      ? JSON.parse(trackPageState)
      : ({ reviewYourClaim: true, repairerOptions: true } satisfies TrackPageStatus);
  });

  const { policyDetails } = startYourClaimState;
  const { propertyDamaged } = aboutTheAccidentState;
  const { additionalDrivers } = moreAboutTheAccidentState;
  const { driverName } = driverOfYourCarState;
  const { hasOwnerDetails } = thirdPartyDetailsState;
  const { carWasTowed, carIsDriveable } = aboutYourCarState;

  // Don't show the page if we can't render the policy card correctly
  if (!policyDetails) {
    navigate(ErrorRoute.SystemUnavailable, {
      state: { referrer: location.pathname },
    });
  }

  const form = useForm<ReviewYourClaimFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: reviewYourClaimState,
  });

  const onEditSection: ReviewYourClaimFormProps["onEditSection"] = (title, route) => {
    gtm(event(`Edit - ${title}`));
    navigate(route);
  };

  const updateTrackPageViewState = (newState: Partial<TrackPageStatus>) => {
    const updatedState = { ...trackPageViewState, ...newState };
    sessionStorage.setItem(STORAGE_KEY_TRACK_PAGE_VIEWS, JSON.stringify(updatedState));
  };

  const onSubmit: ReviewYourClaimFormProps["onSubmit"] = async () => {
    try {
      setBackdrop(true, BackdropMessage.ProcessingYourClaim);

      setReviewYourClaimState({ isSubmitted: true, isCompleted: false });

      const { lodgementDetails, repairers, ...rest } = (await apiClient.updateClaim()).result;

      setReviewYourClaimState({ ...rest, isSubmitted: true, isCompleted: true });

      if (lodgementDetails) {
        setRepairerOptionsState({ isCompleted: true });
        setConfirmationState({ lodgementDetails });
        updateTrackPageViewState({ reviewYourClaim: false, repairerOptions: false });
        navigate(FormRoute.Confirmation);
      } else {
        setRepairerOptionsState({ repairers });
        updateTrackPageViewState({ reviewYourClaim: false });
        navigate(FormRoute.RepairerOptions);
      }
    } catch (e) {
      const error = e as ClaimsMotorCollisionApiException;
      navigate(ErrorRoute.SystemUnavailable, {
        state: {
          referrer: location.pathname,
          exception: { request: `POST /claims/claim`, status: error.status },
        },
      });
    } finally {
      setBackdrop(false, BackdropMessage.ProcessingYourClaim);
    }
  };

  useEffect(() => {
    const { isSubmitted, isCompleted } = reviewYourClaimState;

    if (isSubmitted && !isCompleted) {
      onSubmit({} satisfies ReviewYourClaimFormValues);
    }

    if (trackPageViewState.reviewYourClaim) {
      gtm(virtualPageView({ url: location.pathname.toLocaleLowerCase(), title: documentTitle }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    isSingleVehicleCollisionClaim,
    policyDetails,
    driverName,
    formSummaries: {
      contactDetails: summariseContactDetails(contactDetailsState),
      startYourClaim: summariseStartYourClaim(startYourClaimState),
      aboutTheAccident: summariseAboutTheAccident(aboutTheAccidentState),
      moreAboutTheAccident: summariseMoreAboutTheAccident({ isLiabilityOnlyClaim, ...moreAboutTheAccidentState }),
      whereAndHow: summariseWhereAndHow(whereAndHowState),
      driverOfYourCar: summariseDriverOfYourCar({ additionalDrivers, ...driverOfYourCarState }),
      driverHistory: summariseDriverHistory({ driverName, ...driverHistoryState }),
      thirdPartyDetails: summariseThirdPartyDetails({
        isSingleVehicleCollisionClaim,
        propertyDamaged,
        ...thirdPartyDetailsState,
      }),
      moreThirdPartyDetails: summariseMoreThirdPartyDetails({
        isSingleVehicleCollisionClaim,
        hasOwnerDetails,
        ...moreThirdPartyDetailsState,
      }),
      witnessDetails: summariseWitnessDetails(witnessDetailsState),
      aboutYourCar: summariseAboutYourCar({ isLiabilityOnlyClaim, ...aboutYourCarState }),
      wheresYourCar: summariseWheresYourCar({
        isLiabilityOnlyClaim,
        carWasTowed,
        carIsDriveable,
        ...wheresYourCarState,
      }),
    },
    onSubmit,
    onEditSection,
  };
};

export default useReviewYourClaim;
