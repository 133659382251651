import { ContactDetailsDisplay, MicroFrontend } from "raci-react-library";
import { ContactDetailsFormProps } from "./types";

export const ContactDetailsForm = ({
  isLoading,
  firstName,
  phoneType,
  maskedPhoneNumber,
  maskedEmail,
  onSubmit,
  onMFEAction,
  enableChangeMyDetails,
}: ContactDetailsFormProps) => {
  return enableChangeMyDetails ? (
    isLoading ? null : (
      <ContactDetailsDisplay
        firstName={firstName ?? ""}
        phoneType={phoneType}
        maskedPhoneNumber={maskedPhoneNumber ?? ""}
        maskedEmail={maskedEmail ?? ""}
        onConfirmedCorrect={() => {
          onSubmit({ contactNumber: maskedPhoneNumber, email: maskedEmail });
        }}
        updateContactDetailsUrl={
          // Note: The redirect URL that is sent to MyRAC cannot have protocol in it, due to Sitecore Login not being able to handle it
          `${process.env.REACT_APP_MY_RAC_EDIT_CONTACT_DETAILS_PAGE}?return_url=${window.location.href.replace("https://", "")}` ?? ""
        }
      />
    )
  ) : (
    <MicroFrontend
      name="ConfirmDetails"
      url={process.env.REACT_APP_SPARK_CONFIRM_DETAILS_MFE_URL ?? ""}
      callBack={onMFEAction}
    />
  );
};

export default ContactDetailsForm;
